import React, {useContext} from "react";
import {AlertContext, AlertContextType} from "src/providers/alert.context";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

export const AlertWrapperComponent = (props: any) => {
    const alert = useContext(AlertContext)

    return (
        <>
            <Snackbar
                open={alert.current.open}
                autoHideDuration={alert.current.duration}
                onClose={() => alert.hideAlert()}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert
                    onClose={() => alert.hideAlert()}
                    severity={alert.current.severity}
                >
                    {alert.current.message}
                </Alert>
            </Snackbar>
        </>
    )

}