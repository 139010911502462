import {createContext, FC, ReactNode, useContext, useEffect, useState} from "react";

export type currentAlertType = {
        //id: number;
        open: boolean;
        duration: number;
        severity: "error" | "info" | "success" | "warning" | undefined;
        message: string;
}

export type AlertContextType = {
    showAlert: (type: "error" | "info" | "success" | "warning" | undefined, message: string, duration?: number) => void;
    current: currentAlertType;
    hideAlert: () => void;
}

export const AlertContext = createContext<AlertContextType>({
    showAlert: () => {},
    current: {
        open: false,
        message: "",
        severity: "success",
        duration: 3000
    },
    hideAlert: () => {},
});

type AlertProviderProps = {
    children: ReactNode;
}

export const AlertProvider: FC<AlertProviderProps> = ({children}) => {
    const [alert, setAlert] = useState<currentAlertType>(
        {
            open: false,
            message: "",
            duration: 3000,
            severity: "success"
        }
    )
   const showAlert = (type: "error" | "info" | "success" | "warning" | undefined, message: string, duration?: number) => {
       console.log("SHOW ALERT")
        setAlert(
           {
           open: true,
           message,
           duration: duration || 3000,
           severity: type
       })
   }

   const hideAlert = () => {
       setAlert(prevState =>({
           open: false,
           message: prevState.message,
           severity: prevState.severity,
           duration: 0
       }))
   }

   const data = {
       showAlert,
       current: alert,
       hideAlert
   }

    return (<AlertContext.Provider value={data}>{children}</AlertContext.Provider>)
}

